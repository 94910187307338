import React from 'react'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

export default function DatePicker(props) {
  const { name, label, value, onChange, ...other } = props

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  })

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant='inline'
        inputVariant='outlined'
        label={label}
        format='dd/MM/yyyy'
        name={name}
        value={value}
        onChange={(date) => onChange(convertToDefEventPara(name, date))}
    minDate={new Date('2023-12-23')} // Set the minimum date eg  Oct 1, 2024
	        
	  size='small'
        {...other}
        style={{ marginTop: '8px' }}
      />
    </MuiPickersUtilsProvider>
  )
}
